/* 共通スタイル */
.mainmenu-contents {
    padding: 20px 20px;
    flex-direction: column;
    position: relative;
}

.mainmenu-contents h2 {
    margin-bottom: 30px; /* MENUの下の余白 */
    text-align: center;
    font-size: 3em; /* MENUのフォントサイズを大きく */
}

.mainmenu-contents .main-text {
    margin-bottom: 30px; /* メインテキストの下の余白 */
    text-align: center;
    font-size: 1.5em; /* メインテキストのフォントサイズ */
}

.menu-item {
    display: flex;
    margin-bottom: 60px; /* メニューアイテム間の余白を拡大 */
}

.menu-item.reverse {
    flex-direction: row-reverse;
}

.item-image img {
    width: 70%; /* 画像サイズをフレキシブルに */
    height: auto;
    object-fit: cover;
    border-radius: 10px; /* 画像の角を丸く */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* 影を追加 */
}

.item-details {
    flex: 1;
    padding: 0 5%; /* 左右のパディングを設定 */
}

.item-details h3, .item-details p {
    margin: 10px 0;
    font-size: 1.5em; /* 商品名と価格のフォントサイズを大きく */
}

/* 1080px以上のPC表示用 */
@media (min-width: 1080px) {
    .menu-item {
        align-items: center; /* アイテムを中央に配置 */
    }

    .item-image img {
        width: 50vw; /* 画像の幅をビューポート幅の50%に設定 */
        max-width: 600px; /* 最大幅を設定して過度に大きくならないようにする */
    }

    .item-details {
        margin: 0 5%; /* 画像とテキストの間の余白を狭く */
    }

    .item-details h3 {
        display: inline-block; /* 商品名を横並びに */
        margin-right: 10px; /* 商品名の右の余白 */
    }

    .item-details .price {
        display: inline-block; /* 価格を横並びに */
    }

    .item-details .main-copy {
        margin-top: 50px; /* 商品名と説明テキストの間隔を広く */
    }
}

/* 760px以上1080px以下の表示用 */
@media (min-width: 760px) and (max-width: 1080px) {
    .menu-item {
        align-items: center; /* アイテムを中央に配置 */
        gap: 5px; /* 画像とテキストの間隔を狭める */

    }

    .item-image {
        width: 60%; /* 画像コンテナのサイズを調整 */
        padding: 0; /* 余白をなくす */
    }

    .item-image img {
        width: 70%; /* 画像のサイズを調整 */
    }

    .item-details {
        margin: 0 5%; /* 画像とテキストの間の余白を調整 */
        align-items: center; /* テキストフィールドを中央揃えに */
    }

    .item-details h3, .item-details p {
        font-size: 1.1em; /* テキストのフォントサイズを調整 */
    }
}

/* 760px以下のスマホ表示用 */
@media (max-width: 760px) {
.menu-item, .menu-item.reverse {
flex-direction: column;
}

.item-image img {
    width: 100%; /* スマホ表示では画像の幅を全幅に */
}

.item-details h3, .item-details p {
    font-size: 1.1em; /* スマホ表示ではテキストのフォントサイズを大きく */
}
}