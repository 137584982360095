/* Appクラスのスタイル */
.App {
  width: 100%;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

.test-element {
  width: 100px;
  height: 100px;
  background-color: red;
  margin: 20px;
}