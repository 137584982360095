.product-contents {
    padding: 20px 20px; /* 左右の余白を広く */
    display: flex;
    background-color: black;
    color: white;
    align-items: center; /* 垂直方向の中央揃え */
}

.left-container, .right-container {
    flex: 1;
    padding: 20px;
}

.left-container .title {
    margin-bottom: 50px; /* タイトルとメインテキストの間隔 */
}

.left-container .main-text {
    font-size: 1.3em; /* メインテキストのサイズ */
    font-weight: bold; /* 太文字 */
    margin-bottom: 50px; /* メインテキストとサブテキストの間隔 */
}

.left-container .sub-text {
    font-size: 1.1em; /* サブテキストのサイズ */
    line-height: 1.7; /* 行間を広げる */
}

.left-container .main-text,
.left-container .sub-text {
    text-align: left; /* テキストを左揃えにする */
}

.right-container {
    position: relative;
    display: flex;
    justify-content: center; /* 水平方向の中央揃え */
    align-items: center; /* 垂直方向の中央揃え */
    height: 100%; /* 高さを100%に設定 */
}

.product-text-area {
    display: flex;
    flex-direction: column; /* 垂直方向のスタック */
    justify-content: center; /* 垂直方向の中央揃え */
    height: 100%; /* コンテナの高さを100%に設定 */
}

.image-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;

}

.frame {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
}

.frame > img {
    width: 99%;
    height: 99%;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.frame-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: none;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    border-radius: 10px; /* 角丸をフレームに合わせる */
    transition: transform 0.3s ease, opacity 0.3s ease; /* スケールアニメーションを追加 */
}

.frame:hover .frame-overlay {
    display: flex;
    opacity: 1;
    transform: scale(1.05); /* フレームのオーバーレイを拡大 */
}

.frame:hover .frame-overlay .frame-overlay-text {
    color: black; /* ホバー時のテキスト色を黒に */
}

.frame:hover > img {
    transform: scale(1.05);
}

.circle {
    position: absolute;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease, transform 0.5s ease; /* アニメーション時間を延長 */
}

.circle.hidden {
    opacity: 0; 
    transform: scale(0.95); 
    pointer-events: none; /* 非表示時にマウスイベントを無効に */
    }

.circle-text {
color: black;
transition: transform 0.3s ease;
pointer-events: none;
}

.frame:hover > .circle {
    opacity: 0; /* フレームホバー時にサークルを透明に */
    transform: scale(1.1); /* サークルを少し拡大 */
}

/* 中画面デバイス用 */
@media screen and (max-width: 1080px) {
    .left-container .main-text {
        font-size: 1.1em; /* 中画面でのフォントサイズ */
    }

    .left-container .sub-text {
        font-size: 1em; /* 中画面でのフォントサイズ */
    }
}

@media screen and (max-width: 768px) {
.product-contents {
flex-direction: column; /* スマホ表示時に縦並びに */
}

.left-container, .right-container {
    width: 100%; /* スマホ表示時の幅調整 */
}

.left-container .main-text {
    font-size: 1em; /* 小画面でのフォントサイズ */
}

.left-container .sub-text {
    font-size: 0.9em; /* 小画面でのフォントサイズ */
}

}
