/* GalleryContents.css */
.gallery-contents {
    padding: 20px;
    text-align: center;
    background-color: #333;
}

.gallery-contents h2 {
    font-size: 2em; /* メインテキストのサイズ */
    font-weight: bold; /* 太文字 */
    margin-bottom: 50px; /* メインテキストとサブテキストの間隔 */
    color: white;
}

/* PC表示での背景アレンジ */
@media (min-width: 769px) {
    .gallery-contents {
        background: linear-gradient(to right, white 10%, #212121 25%);
    }
}

/* 既存のスタイル */
.gallery-contents {
    padding: 20px;
    text-align: center;
    background-color: black; /* スマホ表示では背景は黒のまま */
}

.gallery-steps {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.step {
    position: relative;
    flex: 1 1 15%; /* 各カードの基本的なフレキシビリティを設定 */
    max-width: calc(20% - 20px); /* 最大幅を設定して、余白を差し引いた値に */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 通常時の遷移も設定 */
}

.step-number {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
}

.step img {
    width: 100%; /* 親要素に合わせて幅を調整 */
    aspect-ratio: 3 / 4; /* 縦長のアスペクト比を設定 */
    object-fit: cover; /* 画像のアスペクト比を保ちながら要素を埋める */
    border-radius: 5px;
}

.arrow {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* 円形にする */
    border: 2px solid #444; /* 円の縁を濃い色にする */
    transition: transform 0.5s ease;
    width: 50px; /* 円の大きさ */
    height: 50px; /* 円の大きさ */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* 円に影を加える */
    color: #444;
    transition: color 0.5s ease;
}

/* 枠の周りを光が回るアニメーション定義 */
@keyframes rotate-light {
    0% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 1.5px 0 2px white; /* 光の位置を枠に近づける */
    }
    10% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 1px 0.5px 2px white;
    }
    20% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.5px 1px 2px white;
    }
    30% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 1.5px 2px white;
    }
    40% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.5px 1px 2px white;
    }
    50% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -1px 0 2px white;
    }
    55% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.8px -0.8px 2px white;
    }
    60% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.5px -1px 2px white;
    }
    65% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.2px -1.2px 2px white;
    }
    70% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 -1.5px 2px white;
    }
    75% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.2px -1.2px 2px white;
    }
    80% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.5px -1px 2px white;
    }
    90% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.5px -1px 2px white;
    }
    100% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 1px -0.5px 1px rgba(255, 255, 255, 0); /* 光をフェードアウトさせる */
    }
}

.arrow.hovered {
    animation: rotate-light 2s linear 1; /* 両方のアニメーションを同時に1回のみ実行 */
    color: #bbb; /* ホバー時の矢印の色を変更 */
}

/* 画像カードのホバーアクション */
.step:hover {
    transform: scale(1.05); /* ホバー時にカードが少し大きくなる */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* 影を追加 */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
    .gallery-steps {
        flex-direction: column;
    }

    .step-number {
        top: 10px;
        left: 20px;
    }
    
    .arrow {
        transform: rotate(90deg); /* スマホ表示の場合は矢印を90度回転させる */
    }

    .step {
        flex-basis: 80%; /* スマホ表示では2列になるように調整 */
        max-width: 80%; /* 最大幅を設定して、画面幅の約半分に */
        padding: 10px;

    }
}
