.blog-posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: rgb(255, 253, 248); /* コンテンツ全体の背景をクリーム色に */
    padding: 20px;
    align-items: center; /* 中央揃え */
}

.information-text h2 {
    font-size: 2em; /* メインテキストと同じサイズに設定 */
    font-weight: bold; /* 太文字 */
    margin-bottom: 50px; /* メインテキストとサブテキストの間隔 */
}

.blog-post {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white; /* 長方形の背景を白に */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 50%; /* 長方形の幅を設定 */
}

.blog-post-content {
    padding: 20px;
    flex-grow: 1;
    text-align: left;
}

.blog-post-title {
    margin: 0;
    font-size: 1.5em;
    color: #333;
}

.blog-post-date {
    font-size: 1.2em;
    color: #999;
}

.more-button {
    padding: 30px 20px;
    border-radius: 50%;
    border: none;
    background-color: #333;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.more-button:hover {
    background-color: #555;
    transform: scale(1.1); /* ホバー時に少し大きく */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
    .more-button {
        content: '→';
        font-size: 1em; /* アイコンサイズ調整 */
    }
    .information-text h2 {
        margin-bottom: 5px; /* メインテキストとサブテキストの間隔 */
    }

    .blog-post {
        width: 80%; /* 長方形の幅を設定 */
    }
    .blog-post-title {
        margin: 0;
        font-size: 1em;
        color: #333;
    }
    
    .blog-post-date {
        font-size: 0.8em;
        color: #999;
    }
    .more-button {
        padding: 8px 8px;
    }
}