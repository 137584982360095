/* PickupContents.css */
.pickup-contents {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px 0;
    margin-left: 15%; /* 左側にカード一つ分の余白を追加 */
}

.product-card {
    width: 25%; /* カードの幅を%で設定 */
    height: auto; /* 高さを自動に設定 */
    padding-bottom: 10%; /* カードの高さを保持 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    background-color: #fff; /* 背景色を設定 */
    padding-top: 5px; /* カード上面からの間隔 */
    padding-bottom: 1.5%; /* カード下部の余白を減らす */
    border-radius: 15px; /* 角の丸みを増やす */
}

.animation-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-image {
    width: 80%; /* 画像の幅を%で設定 */
    height: auto; /* 高さを自動に設定 */
    /* object-fit: cover; 画像のアスペクト比を維持 */
    margin: 3% auto; /* 画像の周りの余白を%で設定 */
    margin-top: 5px; /* イメージの上に間隔を設ける */
}

.product-name {
    margin: 5px 0 0; /* 商品名の下の間隔を狭くする */
    margin-bottom: 0; /* 商品名と価格の間の余白を狭くする */
    font-size: 2vw; /* フォントサイズをビューポート幅に基づいて設定 */
    text-align: center;
}

.product-price {
    text-align: center; /* 価格を中央寄せ */
    margin-bottom: 10px; /* 価格とボタンの間の余白を増やす */
    margin-top: 5px; /* 価格と画像の間の余白を調整 */
    font-size: 2vw; /* フォントサイズをビューポート幅に基づいて設定 */
}

.view-shop-button {
    background-color: orange; /* オレンジ色の背景 */
    color: white; /* 白色のテキスト */
    border: none;
    border-radius: 5px; /* 角丸 */
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    margin: 0 auto; /* ボタンを中央寄せ */
}

.animation-layer img {
    width: 100%; /* GIFアニメーションをカード幅最大に設定 */
    height: 100%;
    object-fit: cover; /* GIFをカバーするように調整 */
}

.product-card:hover .animation-layer {
    opacity: 1; /* マウスオーバー時にアニメーションを表示 */
}

.content-layer {
    position: relative;
    z-index: 1;
}

.product-name, .add-to-cart {
    color: black; /* デフォルトのテキスト色 */
    transition: color 0.3s ease;
}

.product-card:hover .product-name,
.product-card:hover .product-price,
.product-card:hover .add-to-cart {
    color: white; /* マウスオーバー時にテキスト色を変更 */
}

/* スマホ表示用のスタイル */
@media (max-width: 768px) {
    .pickup-contents {
        flex-wrap: wrap;
        justify-content: space-between; /* カードを均等に配置 */
        padding: 20px 20px; /* 左側に5%の余白を追加 */
        margin-left: 0; /* 左側にカード一つ分の余白を追加 */
    }

    .product-card {
        width: 45%; /* カードの幅を%で設定 */
        height: auto; /* 高さを自動に設定 */
        padding-bottom: 5%; /* カードの高さを保持 */
        margin: 0 1.5%; /* カードの左右に余白を設ける */

    }

    .product-image {
        width: 90%; /* 画像の幅を%で設定 */
        height: auto; /* 高さを自動に設定 */
        margin: 1.2% auto; /* 画像の周りの余白を%で設定 */
    }


    .product-name {
        top: 70%; /* 商品名の位置を%で設定 */
        font-size: 3vw; /* スマホ表示ではフォントサイズをビューポート幅に基づいて設定 */
    }

    .product-price {
        top: 80%; /* 価格の位置を%で設定 */
        font-size: 3vw; /* スマホ表示ではフォントサイズをビューポート幅に基づいて設定 */
    }

    .view-shop-button {
        font-size: 3.5vw; /* ボタンのフォントサイズをビューポート幅に基づいて設定 */
        padding: 4% 8%; /* ボタンのパディングを%で設定 */
        margin-top: 5%; /* ボタン上の余白を%で設定 */

    }
}

