/* Header.css */
header {
    position: fixed; /* ヘッダーを上部に固定 */
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* 背景を半透明に */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10; /* 他の要素より前面に表示 */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px; /* ヘッダーの高さを明示的に設定 */
    padding: 10px 20px; /* パディングを設定 */
    box-sizing: border-box; /* ボックスサイジングをborder-boxに設定 */

}

header:hover {
    background-color: rgba(255, 255, 255, 0.8); /* マウスオーバー時の背景色（より不透明に） */
}

.logo {
    height: 100%; /* ヘッダーの高さに合わせてロゴを調整 */
    display: flex;
    align-items: center; /* ロゴを垂直方向に中央揃え */
}

.logo img {
    max-height: 70px; /* ロゴ画像の最大高さを制限 */
    width: auto; /* 幅は自動で調整されるように */
}

nav {
    display: none;
}

nav.active {
    display: block;
    animation: slideDown 0.8s ease forwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    animation: riseUp 0.8s ease forwards;
}

@keyframes riseUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

nav ul li {
    margin: 0 10px;
    overflow: hidden;
    position: relative;
    height: 40px;
    border-bottom: 1px solid #000; /* 下線を固定 */
}

nav ul li a {
    text-decoration: none;
    color: #000000;
    font-size: 21px; /* フォントサイズを大きく */
    font-weight: bold; /* フォントを太字に */
    display: block;
    position: relative;
    transition: all 0.3s ease;
}

nav.active ul li a {
    animation: riseUp 0.8s ease forwards;
}

@keyframes riseUp {
    from {
        bottom: -80px; /* 下から始まる */
        opacity: 0;
    }
    to {
        bottom: 0; /* 完全に上昇し、表示される */
        opacity: 1;
    }
}

/* .sns-links {
    /* SNSリンクのスタイル */
/* } */

.hamburger {
    display: block; /* モバイルビューでのみ表示されるようにする */
    cursor: pointer;
}

.hamburger span {
    display: block;
    width: 20px; /* サイズを小さく調整 */
    height: 2px; /* サイズを小さく調整 */
    background-color: #333;
    margin: 5px 0;
    transition: 0.4s;
    transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

@media (min-width: 768px) {
    nav {
        display: flex;
    }

    .hamburger {
        display: none;
    }
}

@media (max-width: 767px) {
    header {
        height: 35px; /* ヘッダーの高さを明示的に設定 */
        padding: 10px 20px; /* スマホ表示時のパディングを調整 */
    }

    .logo img {
        max-height: 30px; /* ロゴ画像の最大高さを制限 */
        width: auto; /* 幅は自動で調整されるように */
    }

    .hamburger .bar {
        display: block;
        width: 25px;
        height: 3px;
        background-color: #333;
        margin: 5px 0;
        transition: 0.4s;
    }

    nav.active {
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        background-color: rgba(239, 239, 239, 0.9); /* 背景を半透明に */
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }

    nav ul {
        flex-direction: column;
        align-items: center;
    }

    nav ul li {
        margin: 10px 0;
    }

    nav ul li a {
        bottom: -80px; /* 初期位置を下に設定 */
        opacity: 0; /* 完全に透明 */

    }
}
