/* AboutContents.css */
.about-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: black; /* 背景を黒に設定 */
    color: white; /* テキストを白に設定 */
    margin-top: 30px;
}

.about-text-container {
    flex-basis: 40%; /* コンテナのサイズを狭くする */
    padding: 20px;
    border-radius: 2%; /* 角を丸くする */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 影を付ける */
}

.about-text {
    flex-basis: 40%;
    text-align: left; /* テキストを左揃えにする */

}

.about-image {
    flex-basis: 40%;
    overflow: hidden; /* はみ出した部分を隠す */
    border-radius: 2%; /* 角を丸くする */
    width: 25%; /* 正方形の幅 */
    height: 25%; /* 正方形の高さ */
    margin: auto; /* 中央揃え */
}

.about-image img {
    width: 100%; /* 画像をフレームに合わせる */
    height: auto; /* 高さを自動調整 */
    object-fit: cover; /* 画像をフレームに合わせて調整 */
}

@keyframes imageScroll {
    0% { left: 0; }
    50% { left: -100%; }
    100% { left: 0; }
}

.stylish-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: 2px solid transparent;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

.stylish-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s ease;
    border-radius: 50px;
    z-index: -1;
    }

    .stylish-button:hover:before {
        background-color: #007bff;
        opacity: 0.6;
        }
        
        .stylish-button:hover {
        color: #565656;
        border: 2px solid #007bff; /* ボタンの枠線の色を変更 */
        }
        
        /* ボタンに枠を一周させるアニメーションを追加 */
        .stylish-button:hover {
        animation: borderAnim 2s infinite;
        }
        
        @keyframes borderAnim {
        0% { border-color: #8e8e8e; }
        50% { border-color: black; } /* ホバー時の枠色を黒に変更 */
        100% { border-color: #007bff; }
        }

    @media (max-width: 768px) {
        .about-contents {
            flex-direction: column; /* 子要素を縦に並べる */
            align-items: center; /* 子要素を中央揃え */
        }
    
        .about-text, .about-image {
            flex-basis: 100%; /* 幅を100%に設定 */
        }
    
        .about-image {
            width: 80%; /* 画像の幅を80%に設定 */
            height: auto; /* 高さを自動調整 */
            margin-top: 20px; /* 上の要素との間隔を設定 */
        }
    }