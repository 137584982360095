/* MoreAboutContents.cssの修正 */

.more-about-contents {
    display: flex;
    padding: 20px;
    padding-left: 50px; /* 左側に余白を追加 */
    margin-top: -50px; /* AboutContentsとのオーバーラップ */
}

.gallery-item {
    flex: 0 0 auto;
    width: 25%; /* 幅を統一 */
    margin-right: 20px;
    text-align: center;
    aspect-ratio: 1 / 1; /* 正方形のアスペクト比 */
}

.gallery-item img {
    width: 100%;
    height: 100%; /* 高さを幅と同じに */
    object-fit: cover; /* 画像の比率を維持しつつ、エリアを埋める */
    border-radius: 10px; /* 角丸デザイン */
}

.gallery-item h3 {
    margin-top: 10px; /* 画像とテキストの間隔 */
    font-size: 1.5vw; /* テキストのフォントサイズをビューポート幅に基づいて設定 */
}

/* スマホ表示用のスタイル */
@media (max-width: 760px) {
    .more-about-contents {
        justify-content: center; /* 中央揃えに変更 */
        padding: 20px;
        padding-left: 0x; /* 左側に余白を追加 */
        align-items: center; /* 中央揃えに変更 */

    }

    .gallery-item {
        width: 30%; /* スマホ表示では幅を適切に調整 */
        margin: 0 2.5%; /* 横の余白を適切に調整 */
    }

    .gallery-item img {
        border-radius: 3px; /* 角丸デザイン */
    }

    .gallery-item h3 {
        font-size: 2.5vw; /* スマホ表示ではフォントサイズをビューポート幅に基づいて調整 */
    }
}