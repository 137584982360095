/* Footer.css */
.site-footer {
    padding: 20px;
    background-color: #333;
    color: #fff;
    text-align: center;
}

.footer-content p {
    margin: 10px 0;
}

.sns-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}
