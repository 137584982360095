    /* IntroductionContents.css */
    .introduction-contents {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 50px;
        background-color: #f5f5f5; /* シックな背景色 */
    }

    .introduction-text {
        flex-basis: 50%;
    }

    .introduction-contents h2 {
        font-size: 2em; /* メインテキストと同じサイズに設定 */
        font-weight: bold; /* 太文字 */
        margin-bottom: 50px; /* メインテキストとサブテキストの間隔 */
    }

    .introduction-image img,
    .introduction-image video {
        width: 100%; /* 幅を100%に設定 */
        height: auto; /* 高さを自動調整 */
        border-radius: 10px; /* 角丸デザイン */
        max-width: 600px; /* 最大幅を600pxに制限 */
    }

    .introduction-image video {
        object-fit: cover; /* コンテンツの比率を維持しつつ、コンテナに合わせて調整 */
    }

    .introduction-image img {
        width: 100%;
        height: auto;
        border-radius: 10px; /* 角丸デザイン */
    } 

    @media (max-width: 768px) {
        .introduction-contents {
            flex-direction: column;
            padding: 20px; /* Adjust padding for smaller screens if necessary */
        }
    
        .introduction-text, .introduction-image {
            flex-basis: 100%; /* Set the flex-basis to 100% for full width */
        }
    
        .introduction-image {
            order: -1; /* Place the video above the text on mobile */
            margin-bottom: 20px; /* Add some space between the video and text */
        }
    }
    