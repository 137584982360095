/* TopContents.css */
.top-contents {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 5%;
}

.top-contents img {
    width: 100%;
    height: auto;
}

.explore-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* その他のボタンスタイル */
}

/* パターン3のスタイル */
.dark-background {
    background-color: #333; /* 濃い背景色 */
    color: #fff;
    /* その他のスタイリング */
}